<template>
    <div id="ProductOrigin">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import ZnapTable from '@/components/znap/ZnapTable.vue'

export default {
    name:'ProductOrigin',

    components: { ZnapTable },

    computed: {
        endpoint() {
            return [ this.$ipProduct, 'product-origin' ]
        },
    },

    data: () => ({
        tableName: 'Origem do produto',
        tableIcon: 'mdi-package-variant-closed',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array

            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>